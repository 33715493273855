import React from 'react';
import { Document } from '@contentful/rich-text-types';
import info from '../../images/info-icon.svg';
import close from '../../images/close-icon.svg';
import GatsbyImage, { FluidObject } from "gatsby-image";
import styles from './detail-card.module.scss';
import {RichText} from "../../utils/rich-text";
import cn from 'classnames';

export interface DetailCardImage {
  image?: {
    fluid: FluidObject;
    svg?: { dataURI: string };
  };
  alt?: string;
}

const CardImage: React.FC<DetailCardImage> = ({ image, alt }) => {
  return image?.svg?.dataURI ?
      <img alt={alt} src={image.svg.dataURI}/>
      : image && (<GatsbyImage className={styles.image} fluid={image?.fluid}/>)
}

export type DetailCard = {
  id: string;
  selected: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  title?: {
    json: Document;
  };
  details?: {
    json: Document;
  };
  moreInformation?: {
    json: Document;
  }
  statistic?: string;
} & DetailCardImage;

const BaseDetailCard: React.FC<DetailCard> = ({ id, image, title, details }) => (
  <div className={cn(styles.card, styles.plain)}>
    <div className={styles.header}>
      <CardImage image={image} />
      {title && <RichText content={title} component="h3"/>}
      {details && <RichText className={styles.details} content={details}/>}
    </div>
  </div>
);

const StatisticDetailCard: React.FC<DetailCard> = ({ selected, onClick, image, title, details, statistic, moreInformation }) => {  
  return (
    <div onClick={onClick} className={cn(styles.card, {
      [styles.on]: selected
    })}>
      <div className={styles.header}>
        <div className={styles.content__container}>
          <CardImage image={image} />
          {title && <RichText content={title} component="h3"/>}
          <div className={styles.statistic}>{statistic}</div>
          {details && <RichText className={cn(styles.details,styles.stat)} content={details}/>}
        </div>
        <button type="button" onClick={onClick}>
          <img alt="close" src={info} />
         </button>
      </div>
      <div className={styles.expandable__content}>
        <RichText className={styles.content} content={moreInformation}/>
        <button type="button" onClick={onClick}>
          <img alt="info" src={close} />
        </button>
      </div>
    </div>
  )
}

export const DetailCard: React.FC<DetailCard> = ({ onClick, ...props }) => {
  return props.statistic && props.statistic 
    ? <StatisticDetailCard onClick={onClick} {...props} /> 
    : <BaseDetailCard {...props} />
}
