import GatsbyImage, { FluidObject } from 'gatsby-image';
import styles from './logo.module.scss';
import React from 'react';

export interface LogoContentfulProps {
  title: string;
  fluid: FluidObject | FluidObject[];
  svg: {
    dataURI: string;
  };
}

interface LogoReactProps {
  className?: string;
}
export const Logo: React.FC<LogoContentfulProps & LogoReactProps> = ({
  fluid,
  svg,
  title,
  className,
}) => (
  <div className={className}>
    {fluid && (
      <GatsbyImage
        className={styles.imageWrapper}
        fluid={fluid}
        alt={title}
      />
    )}
    {svg && <img src={svg.dataURI} alt={title} />}
  </div>
);
