import { Document } from '@contentful/rich-text-types';
import cn from 'classnames';
import {
  graphql,
  Link,
} from 'gatsby';
import { FluidObject } from 'gatsby-image/index';
import React from 'react';
import { RichText } from '../../utils/rich-text';
import styles from './testimonials-list.module.scss';
import BackgroundImage from 'gatsby-background-image';

export interface TestimonialsListProps {
  heading?: {
    json: Document;
  };
  subHeading?: {
    json: Document;
  };
  testimonials: {
    id: string;
    heading?: {
      json: Document;
    };
    subHeading?: {
      json: Document;
    };
    url?: string;
    image?: {
      fluid: FluidObject | FluidObject[];
    };
    textColour?: {
      rgb: string;
    };
  }[];
}

export const TestimonialsList: React.FC<TestimonialsListProps> = ({
                                                            heading,
                                                             subHeading,
                                                             testimonials,
                                                           }) => {


  return (
    <>
      <section
        className={cn(styles.container, styles.testimonials)}
      >
          <div className={styles.title}>
            {heading && <RichText content={heading} component="h2"/>}
            {subHeading && <RichText content={subHeading}/>}
          </div>
        {testimonials.map(
          (
            {
              heading: testimonialHeading,
              subHeading: testimonialSubHeading,
              url,
              image,
              id,
              textColour,
            },
            index,
          ) => {
            const textStyle = textColour ? { color: textColour.rgb } : {};
            return (
              <Link
                key={id}
                className={cn(
                  styles.testimonialLink,
                  styles[`testimonial${index}`],
                )}
                to={url}
              >
                <BackgroundImage
                  className={cn(styles.testimonial, styles[`testimonial${index}`])}
                  fluid={image.fluid}
                  role="presentation"
                >
                  <div className={styles.content}>
                    {testimonialHeading && (
                      <RichText style={textStyle} content={testimonialHeading}/>
                    )}
                    {testimonialSubHeading && (
                      <RichText
                        className={styles.testimonialSubHeading}
                        style={textStyle}
                        content={testimonialSubHeading}
                        component="h4"
                      />
                    )}
                    {url && (
                      <div
                        className={styles.spot}
                        title="view details"
                        tabIndex={0}
                        role="button"
                      />
                    )}
                  </div>
                </BackgroundImage>
              </Link>
            );
          },
        )}
      </section>
      <div className={styles.button__container}>
        <Link className={cn(styles.cta, styles.button, styles.desktop)} to={'/'}>Back to Reset Health</Link>
      </div>
    </>
  )
};


export const fragment = graphql`
  fragment TestimonialsListFrag on ContentfulTestimonialsList{
    heading {
      json
    }
    subHeading {
      json
    }
    testimonials {
      id
      heading {
        json
      }
      subHeading {
        json
      }
      url
      image {
        fluid(quality: 75 ) {
          ...GatsbyContentfulFluid
        }
      }
      textColour {
        rgb
      }
    }
  }
`;
