import {
    CommonProps,
    FormQuestion,
  } from '../types';
  import React, {
    useState,
    useEffect,
    useMemo,
  } from 'react';
  import { sortHubspot } from '../utils';
  import styles from './boolean-choice.module.scss';
  import cn from 'classnames';
  
  type SingleChoiceValue = { choice_id: string }[];
  
  export const BooleanChoice: React.FC<FormQuestion & CommonProps<SingleChoiceValue>> = ({
                                                                                            name: questionId,
                                                                                            options,
                                                                                            required,
                                                                                            label: questionLabel,
                                                                                            value=[],
                                                                                            onChange,
                                                                                            onValidationMessage,
  
                                                                                          }) => {
    // hold the question choice id in state for easy reference
    const [isChecked, setIsChecked] = useState(false);
    const onChoiceChange = (checked) => {
      setIsChecked(checked);
      onChange({ id: questionId, value: checked });
    };
    const sortedChoices = useMemo(() => options.sort(sortHubspot), [options]);
    const validationMessage = required && !isChecked ? "You must tick this box to submit the form" : '';
  
    // display error message when required
    useEffect(() => {
      onValidationMessage(validationMessage);
    }, [validationMessage]);

    const checked = isChecked;

    return (
      <div className={styles.booleanChoice}>
        
        <label className={cn(styles.choice, { [styles.checked]: checked})}>
        <input 
          type="checkbox"
          checked={checked}
          name='boolCheckBox'
          required={required}
          onChange={(e) => onChoiceChange(e.target.checked)}
          className={styles.input}
          />
          <span dangerouslySetInnerHTML={{ __html: questionLabel }}></span>          </label>
      </div>
    );
  };
  