import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { graphql } from 'gatsby';
import { Document } from '@contentful/rich-text-types';
import Carousel from '@brainhubeu/react-carousel';
import { useMediaQuery } from '@react-hook/media-query';
import cn from 'classnames';

import { RichText, mobileMaxWidth } from '../../utils';

import styles from './expandable-card-carousel.module.scss';
import * as cardStyles from './card.module.scss';
import '@brainhubeu/react-carousel/lib/style.css';

interface CardCarouselProps {
  title: {
    json: Document;
  };
  headline: {
    json: Document;
  };
  cards: {
    id: string;
    title: {
      json: Document;
    };
    headline: {
      json: Document;
    };
    subline: {
      json: Document;
    };
    summary: {
      json: Document;
    };
    details: {
      json: Document;
    };
  }[];
  expandCta: {
    json: Document;
  };
  collapseCta: {
    json: Document;
  };
}

const SHOW_OVERLAY = false;

export const CardCarousel: React.FC<CardCarouselProps> = ({
  title,
  headline,
  cards,
  collapseCta,
  expandCta,
}) => {
  const isMobile = useMediaQuery(`(max-width: ${mobileMaxWidth}px)`);
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedCard, setSelectedCard] = useState(0);
  const [isClientSide, setIsClientSide] = useState(false);
  const componentRef = useRef<HTMLDivElement>();
  const isAnimatingRef = useRef(false);

  const toggleExpanded = () => {
    setIsExpanded((current) => !current);
    const container = document.getElementsByClassName(styles.container)[0];
    isAnimatingRef.current = true;
    const start = Date.now();
    setTimeout(function step() {
      if (isAnimatingRef.current && Date.now() < start + 600) {
        container.scrollIntoView({ behavior: 'smooth' });
        setTimeout(step, 150);
      }
    }, 150);
    const cardWrapper = document.getElementsByClassName(
      cardStyles.cardWrapper
    )[0] as HTMLElement;
    container.scrollIntoView({ behavior: 'smooth' });
    cardWrapper.addEventListener(
      'animationend',
      () => {
        
        isAnimatingRef.current = false;
        container.scrollIntoView({ behavior: 'smooth' });
      },
      { once: true }
    );
  };

  const onSelectedCardChange = (index: number) => {
    const cardIndex = (cards.length + (index % cards.length)) % cards.length;
    setSelectedCard(cardIndex);
  };

  useEffect(() => {
    setIsClientSide(true); //force render or isMobile can have incorrect value;
  }, []);

  return (
    <section
      className={cn({ [styles.container]: !isMobile })}
      key={isClientSide.toString()}
    >
      <div
        className={cn(styles.module, {
          [styles.overlayShow]: SHOW_OVERLAY && isMobile && isExpanded,
        })}
        ref={componentRef}
      >
        <RichText
          className={cn(styles.title, { [styles.container]: isMobile })}
          content={title}
          component="h2"
        />
        <RichText
          className={cn(styles.headline, { [styles.container]: isMobile })}
          content={headline}
        />
        <div className={styles.carouselWrapper}>
          <Carousel
            centered={isMobile}
            slidesPerPage={isMobile ? 2.3 : 3}
            draggable={isMobile && !isExpanded}
            clickToChange={isMobile && !isExpanded}
            value={isMobile ? selectedCard : 0}
            onChange={onSelectedCardChange}
            keepDirectionWhenDragging={isMobile}
            offset={isMobile ? 2 : 0}
          >
            {cards.map((card, index) => (
              <div
                key={card.id}
                className={styles.card}
                data-card-index={index}
              >
                <div
                  className={cn(cardStyles.cardWrapper, {
                    [cardStyles.mobileExpandedCard]:
                      isMobile && isExpanded && selectedCard === index,
                    [cardStyles.mobileSelectedCard]:
                      isMobile && selectedCard === index,
                    [cardStyles.first]: index === 0,
                    [cardStyles.last]: index === cards.length - 1,
                  })}
                >
                  <div className={cardStyles.card}>
                    <RichText
                      className={cardStyles.title}
                      content={card.title}
                    />
                    <div className={cardStyles.headlineWrapper}>
                      <RichText
                        className={cardStyles.headline}
                        content={card.headline}
                      />
                      <RichText
                        className={cardStyles.subline}
                        content={card.subline}
                      />
                    </div>
                    <RichText
                      className={cardStyles.summary}
                      content={card.summary}
                    />
                  </div>
                  <div
                    className={cn(cardStyles.detailsWrapper, {
                      [cardStyles.detailsShow]:
                        (isMobile && selectedCard === index && isExpanded) ||
                        (!isMobile && isExpanded),
                      [cardStyles.detailsHide]:
                        (isMobile && selectedCard !== index) || !isExpanded,
                    })}
                  >
                    <hr className={cardStyles.divider} />
                    <RichText content={card.details} />
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
        <button
          type="button"
          className={cn(styles.button, styles.detailsButton)}
          onClick={toggleExpanded}
        >
          <RichText content={isExpanded ? collapseCta : expandCta} />
        </button>
      </div>
    </section>
  );
};

export const fragment = graphql`
  fragment CardCarousel on ContentfulExpandableCardCarousel {
    title {
      json
    }
    headline {
      json
    }
    cards {
      id
      title {
        json
      }
      headline {
        json
      }
      subline {
        json
      }
      summary {
        json
      }
      details {
        json
      }
    }
    expandCta {
      json
    }
    collapseCta {
      json
    }
  }
`;
