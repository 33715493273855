import { graphql } from 'gatsby';
import React from 'react';
import { Logo, LogoContentfulProps } from '../../utils/logo';

import styles from './partner-logos.module.scss';

interface PartnerLogosProps {
  logos: (LogoContentfulProps & { id: string })[];
}

export const PartnerLogos: React.FC<PartnerLogosProps> = ({ logos }) => (
  <div className={styles.container}>
    {logos.map(({ id, ...logo }) => (
      <Logo key={id} {...logo} className={styles.logo} />
    ))}
  </div>
);

export const fragment = graphql`
  fragment PartnerLogoFrag on ContentfulPartnerLogos {
    logos {
      id
      fluid(quality: 100) {
        ...GatsbyContentfulFluid
      }
      svg {
        dataURI
      }
      title
    }
  }
`;
