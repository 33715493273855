import { Document } from '@contentful/rich-text-types';
import React from 'react';
import { RichText } from '../rich-text';
import appleBlack from './apple-badge.svg';
import googleButton from './google-badge.svg';

import styles from './app-store.module.scss';

export interface AppStoreContentfulProps {
  appleUrl: string;
  googleUrl: string;
  heading: {
    json: Document;
  };
}
interface AppStoreProps {
  className: string;
}

export const AppStore: React.FC<AppStoreContentfulProps & AppStoreProps> = ({
  heading,
  appleUrl,
  googleUrl,
  className,
}) => (
  <div className={className}>
    <RichText className={styles.heading} content={heading} />
    <div className={styles.buttonsWrapper}>

      <div className={styles.storeButton}>
        <a
          className={styles.appleWrapper}
          href={appleUrl}
          target="_blank"
          rel="noreferrer"
        >
          <img
            className={styles.apple}
            src={appleBlack}
            alt="download from the app store"
          />
        </a>
      </div>
      <div className={styles.storeButton}>
        <a href={googleUrl} target="_blank" rel="noreferrer">
          <img src={googleButton} alt="get it on google play store" />
        </a>
      </div>
    </div>
  </div>
);
