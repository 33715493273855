import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS, Document, INLINES } from '@contentful/rich-text-types';

import styles from './rich-text-render.module.scss';

interface RichTextProps {
  content: {
    json: Document;
  };
  className?: string;
  id?: string;
  style?: {
    [x: string]: string;
  };
  component?: string;
}

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <span className={styles.bold}>{text}</span>,
    [MARKS.ITALIC]: (text) => <span className={styles.italic}>{text}</span>,
    [MARKS.UNDERLINE]: (text) => (
      <span className={styles.underline}>{text}</span>
    ),
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className={styles.para}>{children}</p>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul className={styles.unorderedList}>{children}</ul>
    ),
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      try {
        const defaultLocale = 'en-US';
        const fileUrl = node.data.target.fields.file[defaultLocale].url;

        return <a href={fileUrl}>{children}</a>;
      } catch {
        return <span>{children}</span>;
      }
    }, 
  },
};
export const RichText: React.FC<RichTextProps> = ({
  content,
  className,
  id,
  style,
  component = 'div',
}) =>
  React.createElement(
    component,
    { className, id, style },
    documentToReactComponents(content?.json, options)
  );
