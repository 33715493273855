import { CommonProps, FormQuestion } from '../types';
import React, { useEffect } from 'react';
import styles from './open-ended.module.scss';
import cn from 'classnames';

// type OpenEndedValue = { text: string }[];

const EMAIL_REGEX = /.+@.+\...+/;
export const OpenEnded: React.FC<
  FormQuestion & CommonProps<string>
> = ({
  name,  
  type,
  fieldType,
  validation,
  required,
  value='',
  onChange,
  onValidationMessage,
}) => {
  const onAnswerChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChange({ id: name, value: e.target.value });
  }
    

  const requiredMessage =
    required && value?.length === 0
      ? 'This field is required'
      : '';
  const validationMessage =
    (validation?.name === 'email' || name==='email') && !EMAIL_REGEX.test(value)
      ? "You must enter a valid email"
      : '';

  useEffect(() => {
    onValidationMessage(requiredMessage || validationMessage);
  }, [requiredMessage, validationMessage]);

  if (fieldType === 'text') {

    if(name==='email') {
      return (
        <input
          className={styles.openEnded}
          type="email"
          value={value || ''}
          onChange={onAnswerChange}
        />
      );
  
    } else {
      return (
        <input
          className={styles.openEnded}
          type="text"
          value={value || ''}
          onChange={onAnswerChange}
        />
      );  
    }

  }
  if (fieldType === 'textarea') {
    return (
      <textarea
        className={cn(styles.openEnded, styles.multiline)}
        onChange={onAnswerChange}
        cols={6}
      >
        {value}
      </textarea>
    );
  }
};
