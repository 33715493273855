import { graphql } from 'gatsby';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import { Document } from '@contentful/rich-text-types';
import { RichText } from '../../utils/rich-text';
import { UseSiteMetadata } from '../../utils/use-site-meta-data';
import { SocialShare } from '../social-share';
import React from 'react';

import styles from './biographies.module.scss';

interface BiographiesProps {
  title: {
    json: Document;
  };
  biographies: {
    name: string;
    personName: {
      json: Document;
    };
    position: {
      json: Document;
    };
    body: {
      content: {
        content: {
          value: string;
        };
      }
      json: Document;
    };
    qualifications: {
      json: Document;
    };
    image: {
      fluid: FluidObject | FluidObject[];
    };
  }[];
}

export const Biographies: React.FC<BiographiesProps> = ({
                                                          title,
                                                          biographies,
                                                        }) => {

  const { siteUrl } = UseSiteMetadata();
  return (
    <section className={styles.container}>
      <RichText className={styles.title} content={title} component="h2"/>
      <div className={styles.biographies}>
        {biographies.map(
          ({ name, personName, position, body, qualifications, image }) => (
            <div className={styles.biography}>
              <GatsbyImage className={styles.image} fluid={image.fluid}/>
              <div className={styles.content}>
                <RichText className={styles.name} content={personName}/>
                <RichText className={styles.position} content={position}/>
                <RichText className={styles.body} content={body}/>
                <RichText
                  className={styles.qualifications}
                  content={qualifications}
                />
              </div>
            </div>
          ),
        )}
      </div>
    </section>
  );
};

export const fragment = graphql`
  fragment BiographyFrag on ContentfulBiographies {
    title {
      json
    }
    biographies {
      name
      personName {
        json
      }
      position {
        json
      }
      body {
        content {
          content {
            value
          }
        }
        json
      }
      qualifications {
        json
      }
      image {
        fluid(quality: 75) {
          src
        }
      }
    }
  }
`;
