import React, { useEffect, useMemo, useState } from 'react';
import styles from './HubspotGdprBlock.module.scss';
import { GDPRValueType } from '../types';
import cn from 'classnames';


interface HubspotGdprBlockProps {
  consentOptions: string,
  gdprValues: GDPRValueType,
  displayedValidationMessage: string,
  onValidationMessage: (x: string) => void,
  onChange: (x: GDPRValueType) => void 
}

export const HubspotGdprBlock: React.FC<HubspotGdprBlockProps> = ({
    consentOptions,  // these are provided as a JSON string from Hubspot and we are going to parse that string here
    gdprValues,
    displayedValidationMessage,
    onChange,
    onValidationMessage
  }) => {    

    const legalConsentOptions = JSON.parse(consentOptions);          



    // communications consent
    const communicationCheckboxes = legalConsentOptions.communicationConsentCheckboxes.map((a) => {

      // get existing values 
      const existing = gdprValues.consent?.communications?.find((b) => {
        return b.subscriptionTypeId == a.communicationTypeId;
      });

      return (
          <label className={cn(styles.choice, { [styles.checked]: existing?.value})} key={a.communicationTypeId}>          
          <input
              type="checkbox"
              checked={existing?.value}
              name={a.communicationTypeId}
              required={true}
              onChange={(e) => { onChange({...gdprValues, 
                consent: {...gdprValues.consent, 
                  communications: [{
                    value: e.target.checked,
                    subscriptionTypeId: a.communicationTypeId,
                    text: a.label
                  }]
                }}) }}
              className={styles.input}
          />
          <span dangerouslySetInnerHTML={{ __html: a.label }}></span>
          </label>        
      );
    });

    const validationMessage = (legalConsentOptions.processingConsentType == "REQUIRED_CHECKBOX" && !gdprValues.consent?.consentToProcess) ? "You must tick this box to submit this form" : '';
  
    // display error message when required
    useEffect(() => {
      onValidationMessage(validationMessage);
    }, [validationMessage]);
    
    // processing consent
    const processingCheckbox = legalConsentOptions.processingConsentType == "REQUIRED_CHECKBOX" && 
        <label  className={cn(styles.choice, { [styles.checked]: gdprValues.consent?.consentToProcess})}>          
        <input
            type="checkbox"
            checked={gdprValues.consent?.consentToProcess}
            name="processingConsent"
            required={true}
            onChange={(e) => { 
              const consent = {...gdprValues.consent, consentToProcess: e.target.checked, text: legalConsentOptions.processingConsentCheckboxLabel};  
              onChange({...gdprValues, consent: consent});
            }}
            className={styles.input}
        />
        <span dangerouslySetInnerHTML={{ __html: legalConsentOptions.processingConsentCheckboxLabel }}></span>
        </label>;  

    return (
      
        <div className={styles.gdprBlock}>

          <p dangerouslySetInnerHTML={{__html: legalConsentOptions.communicationConsentText}}></p>
          { communicationCheckboxes }

          <p dangerouslySetInnerHTML={{__html: legalConsentOptions.processingConsentText}}></p>
          { processingCheckbox && processingCheckbox }         

          {displayedValidationMessage && (
            <span className={styles.validation}>{displayedValidationMessage}</span>
          )}                   

        </div>


    );
  };
  