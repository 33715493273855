import React from 'react';
import { FormQuestion } from './types';
import { MultipleChoice, OpenEnded, SingleChoice,BooleanChoice } from './questionTypes';

import styles from './question.module.scss';
import cn from 'classnames';

const QuestionTypeMap = {
  checkbox: MultipleChoice,
  text: OpenEnded,
  textarea: OpenEnded,
  booleancheckbox: BooleanChoice,
};
interface QuestionProps {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange({ id, value }: { id: string; value: any });
  onValidationMessage: (x: string | null | undefined) => void;
  validationMessage: string;
  hideNumbers: boolean;
}
export const Question: React.FC<FormQuestion & QuestionProps> = ({
  fieldType,
  label,
  options,
  validationMessage,
  hideNumbers,
  ...props
}) => {
  const UnknownQuestionType = () => (
    <div>{`unknown question type ${fieldType}`}</div>
  );
  const QuestionType = QuestionTypeMap[fieldType] || UnknownQuestionType;
  const oneAnswerMultiChoice = fieldType === 'checkbox' && options?.length === 1;
  return (
    <li className={cn('question', styles.question, {[styles.hideNumber] : hideNumbers})}>
      <div
        className={cn(styles.questionBlock, {
          [styles.oneAnswer]: oneAnswerMultiChoice,
        })}
      >
        <div className={styles.questionHeading}>          
          { (fieldType === "booleancheckbox") ? '' : <p dangerouslySetInnerHTML={{ __html: label }}></p> }
        </div>
        <QuestionType options={options} fieldType={fieldType} label={label} {...props} />
      </div>
      {validationMessage && (
        <span className={styles.validation}>{validationMessage}</span>
      )}
    </li>
  );
};
