import React from 'react';
import {
  graphql,
  Link,
} from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { Document } from '@contentful/rich-text-types';
import { RichText } from '../../utils';
import {
  TestimonialsListProps,
} from '../testimonials-list/testimonials-list';
import cn from 'classnames';

import styles from './testimonials.module.scss';

interface TestimonialsModuleProps {
  heading?: {
    json: Document;
  };
  subHeadling?: {
    json: Document;
  };
  link: {
    id: string;
    target: string;
    text: string;
    openInNewPage: boolean;
  }
  textPosition?: boolean;
  testimonialsCount?: number;
  testimonialsList?: TestimonialsListProps;
}

export const TestimonialsModule: React.FC<TestimonialsModuleProps> = ({
                                                                        link: {text, target},
                                                                        heading,
                                                                        subHeadling,
                                                                        textPosition = false,
                                                                        testimonialsList,
                                                                        testimonialsCount = null,
                                                                      }) => {

  const testimonialsLimit = testimonialsCount || testimonialsList.testimonials.length;

  return (
    <>
      <section
        className={cn(styles.container, styles.testimonials, {
          [styles.textLeft]: textPosition,
          [styles.textRight]: !textPosition,
        })}
      >
        {(heading || subHeadling) && (
          <div className={styles.title}>
            {heading && <RichText content={heading} component="h2"/>}
            {subHeadling && <RichText content={subHeadling}/>}
            <Link className={cn(styles.cta, styles.button, styles.desktop)} to={target}>{text}</Link>
          </div>
        )}
        {testimonialsList?.testimonials?.slice(0, testimonialsLimit).map(
          (
            {
              heading: testimonialHeading,
              subHeading: testimonialSubHeading,
              url,
              image,
              id,
              textColour,
            },
            index,
          ) => {
            const textStyle = textColour ? { color: textColour.rgb } : {};
            return (
              <Link
                key={id}
                className={cn(
                  styles.testimonialLink,
                  styles[`testimonial${index}`],
                )}
                to={url}
              >
                <BackgroundImage
                  className={cn(styles.testimonial, styles[`testimonial${index}`])}
                  fluid={image.fluid}
                  role="presentation"
                >
                  {testimonialHeading && (
                    <RichText style={textStyle} content={testimonialHeading}/>
                  )}
                  {testimonialSubHeading && (
                    <RichText
                      className={styles.testimonialSubHeading}
                      style={textStyle}
                      content={testimonialSubHeading}
                      component="h4"
                    />
                  )}
                  {url && (
                    <div
                      className={styles.spot}
                      title="view details"
                      tabIndex={0}
                      role="button"
                    />
                  )}
                </BackgroundImage>
              </Link>
            );
          },
        )}
      </section>
      <div className={styles.button__container}>
        <Link className={cn(styles.cta, styles.button, styles.mobile)} to={target}>{text}</Link>
      </div>
    </>
  );
};

export const fragment = graphql`
  fragment TestimonialsModuleFrag on ContentfulTestimonialsModule {
    heading {
      json
    }
    subHeadling {
      json
    }
    textPosition
    testimonialsList {
      ...TestimonialsListFrag
    }
    link {
      text
      target
      openInNewPage
    }
  }
`;
