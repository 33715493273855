import React from 'react';
import styles from './Overlay.module.scss';
import cn from 'classnames';

export interface OverlayProps {
  on: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const Overlay: React.FC<OverlayProps> = ({ on, onClick }) =>  
  <div onClick={onClick} className={cn(styles.main, { [styles.on]: on })}></div>