/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { FluidObject } from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import { graphql } from 'gatsby';
import { Document } from '@contentful/rich-text-types';

import styles from './image-slider.module.scss';
import { mobileMaxWidth, RichText } from '../../utils';
import cn from 'classnames';
import { useMediaQuery } from '@react-hook/media-query';

interface ImageSliderProps {
  left: ImageSliderImage;
  right: ImageSliderImage;
}

interface ImageSliderImage {
  image: {
    fluid: FluidObject | FluidObject[];
  };
  heading: {
    json: Document;
  };
  body: {
    json: Document;
  };
}

const DRAG_MARGIN = 125;

const calculateDividerPos = (
  parentElement: HTMLElement,
  dragPosition: number
) => {
  return Math.min(
    Math.max(DRAG_MARGIN, dragPosition - parentElement.offsetLeft),
    parentElement.offsetWidth - DRAG_MARGIN
  );
};

export const ImageSlider: React.FC<ImageSliderProps> = ({ left, right }) => {
  const dragRef = useRef<HTMLDivElement>();
  const dragHandle = useRef<HTMLDivElement>();
  const currentDragPos = useRef<number>();
  const isMobile = useMediaQuery(`(max-width: ${mobileMaxWidth}px)`);
  const [showText, setShowText] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const onDragStart = (event: any) => {
    setIsDragging(true);
  };
  const onDragEnd = () => {
    setIsDragging(false);

    [styles.left, styles.textBackground, styles.right, styles.divider].forEach(
      (className) => {
        const elements = dragRef.current.getElementsByClassName(className);
        for (let i = 0; i < elements.length; i++) {
          (elements.item(i) as HTMLElement).style = '';
        }
      }
    );
  };
  const onDrag = (event: any) => {
    let pageX = 0;
    if (event.touches) {
      pageX = event.touches[0].pageX || 0;
    } else {
      pageX = event.pageX || 0;
    }
    if (pageX > 0) {
      const dragPos = calculateDividerPos(dragRef.current, pageX);
      setSliderPos(dragPos);
    }
  };

  const setSliderPos = (dragPos) => {
    currentDragPos.current = dragPos;
    const rightPane = dragRef.current?.getElementsByClassName(
      styles.right
    )[0] as HTMLElement;
    if (!rightPane) {
      return;
    }
    const panelWidth = dragRef.current.getBoundingClientRect().width;

    rightPane.style.clipPath = `inset(0 0 0 ${dragPos}px)`;
    (dragRef.current?.getElementsByClassName(
      styles.divider
    )[0] as HTMLElement).style.marginLeft = `${dragPos}px`;
    const textBox = dragRef.current?.getElementsByClassName(styles.notMobile)[0] as HTMLElement;
    const textBoxWidth = textBox.getBoundingClientRect().width;
    textBox.style.marginLeft = `${(panelWidth - textBoxWidth) / 2 - (panelWidth / 2 - dragPos)}px`
  };

  const preventDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  useEffect(() => {
    const onInview = (entries) => {
      if (entries[0].isIntersecting) {
        setShowText(true);
        observer1.disconnect();
        observer2.disconnect();
      }
    };

    const observer1 = new IntersectionObserver(onInview, {
      root: document.querySelector('#scrollArea'),
      rootMargin: '0% 0% 0% 0%',
      threshold: isMobile ? 0.45 : 0.9,
    });

    const observer2 = new IntersectionObserver(onInview, {
      root: document.querySelector('#scrollArea'),
      rootMargin: '0% 0% -75% 0%',
      threshold: 0.1,
    });
    observer1.observe(dragRef.current);
    observer2.observe(dragRef.current);
    return () => {
      observer1.disconnect();
      observer2.disconnect();
    };
  }, [isMobile]);

  return (
    <section
      className={cn(styles.module, {
        [styles.showText]: showText,
        [styles.dragging]: isDragging,
      })}
      ref={dragRef}
      onMouseMove={(e) => isDragging && onDrag(e)}
      onMouseUp={onDragEnd}
      onMouseLeave={onDragEnd}
    >
      <BackgroundImage className={styles.imageWrapper} fluid={left.image.fluid} style={{backgroundPosition: 'top left'}}>
        <div className={styles.left}>
          <div id="text-left" className={cn(styles.textBackground, styles.mobileOnly)}>
            <RichText content={left.heading} component="h3" />
            <RichText content={left.body} />
          </div>
        </div>
      </BackgroundImage>
      <div className={styles.right}>
        <BackgroundImage
          className={styles.imageWrapper}
          fluid={right.image.fluid}
          draggable={false}
          style={{backgroundPosition: 'top right'}}
        />
        <div id="text-right" className={cn(styles.textBackground, styles.mobileOnly)}>
          <RichText content={right.heading} component="h3" />
          <RichText content={right.body} />
        </div>
      </div>
      <div className={cn(styles.textBackground, styles.notMobile)}>
        <RichText content={left.heading} component="h3" />
        <RichText content={right.heading} component="h3" />
        <RichText content={left.body} />
        <RichText content={right.body} />
      </div>
      <div
        className={styles.dragPrevent}
        draggable={false}
        onDragStart={preventDrag}
      />
      <div
        className={styles.divider}
        onMouseDown={onDragStart}
        onTouchMove={onDrag}
        onTouchStart={onDragStart}
        onTouchEnd={onDragEnd}
        onDragStart={preventDrag}
        role="button"
        draggable
        aria-label="drag to blend options"
        tabIndex={0}
      >
        <div style={{ flexGrow: 4.25 }} />
        <div className={styles.handle} ref={dragHandle} />
        <div style={{ flexGrow: 2 }} />
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ImageSliderFrag on ContentfulImageSlider {
    left {
      heading {
        json
      }
      body {
        json
      }
      image {
        fluid(quality: 75, maxWidth: 3840 ) {
          ...GatsbyContentfulFluid
        }
      }
    }
    right {
      heading {
        json
      }
      body {
        json
      }
      image {
        fluid(quality: 75, maxWidth: 3840 ) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`;
