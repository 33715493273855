import React, {useState, useCallback} from 'react';
import { graphql } from 'gatsby';
import { Document } from '@contentful/rich-text-types';
import { RichText } from '../../utils';
import styles from './detail-cards.module.scss';
import cn from 'classnames';
import {DetailCard} from "./detail-card";
import {Overlay} from "../Overlay/Overlay";

interface DetailCardsProps {
  title?: {
    json: Document;
  };
  cards: DetailCard[];
}

export const DetailCards: React.FC<DetailCardsProps> = ({
    title,
    cards = [],
  }) => {
    const [selectedCard, setSelectedCard] = useState<string>();

    const createClickHandler = useCallback((id: string) => (e: React.MouseEvent<HTMLElement>) => {
      const on = selectedCard !== undefined && selectedCard === id ? undefined : id;

      setSelectedCard(on);
      e.preventDefault();
      e.stopPropagation();
    }, [selectedCard]);

    return (
      <>
        <section className={cn(styles.container, styles.module)}>
          {title && (
            <RichText content={title} component="h2" className={styles.title}/>
          )}
          <div className={styles.cardLayout}>
            {cards?.map(({id, ...card}) =>
              <DetailCard
                key={id}
                id={id}
                selected={selectedCard === id}
                onClick={createClickHandler(id)}
                {...card} />
              )}
          </div>
        </section>
        <Overlay on={!!selectedCard} onClick={createClickHandler(undefined)} />
      </>
  );
}

export const fragment = graphql`
  fragment DetailCardsFrag on ContentfulDetailCardsModule {
    title {
      json
    }
    cards {
      id
      image {
        fluid(quality: 75, maxWidth: 3840 ) {
          ...GatsbyContentfulFluid
        }
        svg {
          dataURI
        }
      }
      title {
        json
      }
      details {
        json
      }
      moreInformation {
        json
      }
      statistic
    }
  }
`;
