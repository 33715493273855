import React from 'react';
import { graphql } from 'gatsby';
import { Document } from '@contentful/rich-text-types';
import { get as getCookie, set as setCookie } from 'js-cookie';
import { RichText } from '../../utils/rich-text';

import styles from './gdpr-banner.module.scss';
import cn from 'classnames';
import { useRef, useState } from 'react';

export interface GdprBannerProps {
  bannerText: {
    json: Document;
  };
  acceptButtonText: {
    json: Document;
  };
  rejectButtonText: {
    json: Document;
  };
  rememberConsent: boolean;
}
export const fragment = graphql`
  fragment GDPRFrag on Query {
    contentfulGdprCookieBanner {
      bannerText {
        json
      }
      acceptButtonText {
        json
      }
      rejectButtonText {
        json
      }
      rememberConsent
    }
  }
`;

// const COOKIE_ID = 'gatsby-gdpr-google-tagmanager';
const COOKIE_ID_FACEBOOK = 'gatsby-gdpr-facebook-pixel';
const COOKIE_ID_GOOGLE_ANALYTICS = 'gatsby-gdpr-google-analytics';
const COOKIE_ID_GOOGLE_TAG_MANAGER = 'gatsby-gdpr-google-tagmanager';
export const GDPRBanner: React.FC<GdprBannerProps> = ({
  bannerText,
  acceptButtonText,
  rejectButtonText,
  rememberConsent,
}) => {
  // const cookie = getCookie(COOKIE_ID);
  const hasConsented = getCookie(COOKIE_ID_GOOGLE_TAG_MANAGER) && getCookie(COOKIE_ID_FACEBOOK) && getCookie(COOKIE_ID_GOOGLE_ANALYTICS);
  const [hideBanner, setHideBanner] = useState(false);
  const bannerRef = useRef<HTMLDivElement>();

  if (hasConsented) {
    return null;
  }

  const acceptCookies = (shouldAccept: boolean) => {
    const setCookies = () => {
      setCookie(COOKIE_ID_FACEBOOK, 'true', {
        ...(rememberConsent && { expires: 365 }),
      });
      setCookie(COOKIE_ID_GOOGLE_ANALYTICS, 'true', {
        ...(rememberConsent && { expires: 365 }),
      });
      setCookie(COOKIE_ID_GOOGLE_TAG_MANAGER, 'true', {
        ...(rememberConsent && { expires: 365 }),
      });
    };
    const timeout = setTimeout(setCookies, 600);
    bannerRef.current.addEventListener('animationend', () => {
      clearTimeout(timeout);
      setCookies();
    });
    setHideBanner(true);
  };

  return (
    <div
      className={cn(styles.banner, { [styles.hide]: hideBanner })}
      ref={bannerRef}
    >
      <div className={styles.container}>
        <RichText content={bannerText} />
        <div className={styles.buttonWrapper}>
          <button
            className={cn(styles.button, styles.rejectButton)}
            onClick={() => acceptCookies(false)}
          >
            <RichText content={rejectButtonText} />
          </button>
          <button className={styles.button} onClick={() => acceptCookies(true)}>
            <RichText content={acceptButtonText} />
          </button>
        </div>
      </div>
    </div>
  );
};
