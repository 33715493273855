import React from 'react';
import { graphql } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import { Document } from '@contentful/rich-text-types';
import { RichText, VideoOverlay } from '../../utils';
import playButton from '../../images/play-button.svg';

import styles from './hero.module.scss';
import cn from 'classnames';
import { useState } from 'react';

interface HeroProps {
  image: {
    fluid: FluidObject | FluidObject[];
  };
  headline: {
    json: Document;
  };
  subline: {
    json: Document;
  };
  video: {
    linkText: {
      json: Document;
    };
    url: string;
  };
  fullWidth: boolean;
  useBackgroundVideo: boolean;
  backgroundVideo;
  backgroundVideoPlaceholderImage;
  showScrollDownArrow: boolean;
  textColour?: {
    rgb: string;
  };
}

export const Hero: React.FC<HeroProps> = ({
  image,
  headline,
  subline,
  video,
  fullWidth,
  useBackgroundVideo,
  backgroundVideo,
  backgroundVideoPlaceholderImage,
  showScrollDownArrow,
  textColour,
}) => {
  const isFullWidth = fullWidth !== false;
  const textColourStyle = textColour ? { color: textColour.rgb } : {};
  const [showVideo, setShowVideo] = useState(false);

  const showVideoClickHandler = (e: React.MouseEvent<HTMLElement>) => {
    setShowVideo(true);

    e.stopPropagation();
    e.preventDefault();
  };

const scrollClickHandler = (e: React.MouseEvent<HTMLElement>) => {
  e.stopPropagation();
  e.preventDefault();
  const rect = document.getElementById('heroScrollButton').getBoundingClientRect();
  window.scrollTo({ top: rect.y + rect.height + 12, behavior: 'smooth' });
}

  return (
    <BackgroundImage
      onClick={video ? showVideoClickHandler : undefined}
      className={cn(styles.hero, {
        [styles.fullWidth]: isFullWidth,
        [styles.container]: !isFullWidth,
        [styles.clickable]: video
      })}
      fluid={image.fluid}
      style={textColourStyle}
    >
      {useBackgroundVideo && (
      <div className={styles.bgvideowrapper}>
        <div className={styles.videoOpacityOverlay}></div>
        <video poster={backgroundVideoPlaceholderImage.file.url} className={styles.bgvideo} muted autoPlay loop playsInline src={backgroundVideo.file.url} />
      </div>)}
      <div className={cn({ [styles.container]: isFullWidth })}>
        {headline && (
          <RichText
            className={cn(styles.headline, {[styles.fullWidthHeadline]: useBackgroundVideo})}
            content={headline}
            component="h1"
          />
        )}
        {subline && <RichText className={cn(styles.subline, {[styles.fullWidthSubline]: useBackgroundVideo})} content={subline} />}
        {video && (
          <button
            type="button"
            className={cn(styles.video, {[styles.fullWidthVideoButton]: useBackgroundVideo})}
            onClick={showVideoClickHandler}
          >
            <img src={playButton} alt="play video" />
            <RichText content={video.linkText} />
          </button>
        )}
      </div>
      {showVideo && (
        <VideoOverlay url={video.url} onClose={() => setShowVideo(false)} />
      )}
      {showScrollDownArrow && (
        <a id="heroScrollButton" href="javascript:void(0);" onClick={scrollClickHandler} className={styles.scrollButtonWrapper}>
        </a>
      )}
    </BackgroundImage>
  );
};

export const fragment = graphql`
  fragment HeroFrag on ContentfulHero {
    image {
      fluid(quality: 75, maxWidth: 3840 ) {
        ...GatsbyContentfulFluid
      }
    }
    headline {
      json
    }
    subline {
      json
    }
    video {
      linkText {
        json
      }
      url
    }
    fullWidth
    useBackgroundVideo
    backgroundVideo {
      file {
        url
      }
    }
    backgroundVideoPlaceholderImage {
      file {
        url
      }
    }
    showScrollDownArrow
    textColour {
      rgb
    }
  }
`;
