import React from 'react';
import Hamburger from 'hamburger-react';
import { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { useMediaQuery } from '@react-hook/media-query';
import { mobileMaxWidth } from '../../utils';
import cn from 'classnames';
import { Logo, LogoContentfulProps } from '../../utils/logo';

import styles from './header.module.scss';

export interface HeaderContentfulProps {
  cta: {
    text: string;
    target: string;
  };
  links: {
    id: string;
    text: string;
    target: string;
  }[];
  logo: LogoContentfulProps;
}

interface HeaderProps {
  location: {
    pathname: string;
  };
}

export const Header: React.FC<HeaderContentfulProps & HeaderProps> = ({
  cta,
  links,
  logo,
  location,
}) => {
  const isMobile = useMediaQuery(`(max-width: ${mobileMaxWidth}px)`);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const onApplyPage = new RegExp(`${cta.target}/?$`).test(location.pathname);

  return (
    <section className={styles.header}>
      <div className={styles.gradient} />
      <div className={styles.container}>
        <div className={styles.mobileOnly}>
          {<Hamburger size={20} label="Show menu" toggled={showMobileMenu} toggle={setShowMobileMenu} />}
        </div>
        {logo && (
          <Link
            to="/"
            aria-label="home"
            onClick={() => setShowMobileMenu(false)}
          >
            <Logo
              title={logo.title}
              fluid={logo.fluid}
              svg={logo.svg}
              className={styles.logo}
            />
          </Link>
        )}
        <div className={styles.spacer} />
        <div
          className={cn(styles.links, { [styles.showMenu]: showMobileMenu })}
        >
          {links.map(({ id, text, target }) => (
            <Link key={id} to={target} onClick={() => setShowMobileMenu(false)}>
              {text}
            </Link>
          ))}
        </div>
        <div className={styles.spacer} />
        {onApplyPage === false ?
        <Link
          aria-disabled={onApplyPage}
          className={cn(styles.button, { [styles.off]: onApplyPage })}
          to={cta.target}
          onClick={() => {
            setShowMobileMenu(false)}
          }
        >
          {cta.text}
        </Link>
        : <button type="button" disabled className={cn(styles.button, styles.off)}>{cta.text}</button>}
      </div>
    </section>
  );
};

export default Header;

export const query = graphql`
  fragment HeaderFrag on ContentfulHeader {
    cta {
      text
      target
    }
    links {
      id
      text
      target
    }
    logo {
      title
      fluid(quality: 100) {
        ...GatsbyContentfulFluid
      }
      svg {
        dataURI
      }
    }
  }
`;
