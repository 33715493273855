import { graphql } from 'gatsby';
import React from 'react';
import { Document } from '@contentful/rich-text-types';
import cn from 'classnames';

import { RichText } from '../../utils/rich-text';
import styles from './two-column.module.scss';

interface TwoColumnProps {
  left: TextBlock;
  right: TextBlock;
}

interface TextBlock {
  title: {
    json: Document;
  };
  body: {
    json: Document;
  };
}

// export const TwoColumn: React.FC<TwoColumnProps> = ({left, right}) => <DetailCards cards={[left, right]} twoColumn={true} />
export const TwoColumn: React.FC<TwoColumnProps> = ({ left, right }) => (
  <section className={styles.container}>
    <RichText
      className={cn(styles.title, styles.titleLeft)}
      content={left.title}
      component="h2"
    />
    <RichText
      className={cn(styles.body, styles.bodyLeft)}
      content={left.body}
    />
    <RichText
      className={cn(styles.title, styles.titleRight)}
      content={right.title}
      component="h2"
    />
    <RichText
      className={cn(styles.body, styles.bodyRight)}
      content={right.body}
    />
  </section>
);

export const fragment = graphql`
  fragment TwoColumnFragment on ContentfulTwoColumn {
    left {
      ...TextBlockFrag
    }
    right {
      ...TextBlockFrag
    }
  }

  fragment TextBlockFrag on ContentfulTextBlock {
    title {
      json
    }
    body {
      json
    }
  }
`;
