import { CommonProps, FormQuestion } from '../types';
import React, { useEffect, useMemo } from 'react';
import { sortFn, sortHubspot } from '../utils';
import styles from './multiple-choice.module.scss';
import cn from 'classnames';

type MultipleChoiceValue = { choice_id: string }[];

export const MultipleChoice: React.FC<
  FormQuestion & CommonProps<MultipleChoiceValue>
> = ({
  name,
  options,
  required,
  value = [],
  onChange,
  onValidationMessage,
}) => {
  const onChoiceChange = (choice, checked) => {

    const newVal = value;      
    if (checked) {
      newVal[choice] = choice;
    } else {
      newVal[choice] = null;
    }

    onChange({ id: name, value: newVal });
  };
  const sortedChoices = useMemo(() => options.sort(sortHubspot), [options]);

  // TODO: handle Hubspot validations
  const validationMessage =
    required && (value.length == 0)
      ? "This is a required field"
      : '';

  useEffect(() => {
    onValidationMessage(validationMessage);
  }, [validationMessage]);

  return (
    <div className={styles.multipleChoice}>
      {sortedChoices.map(({ label, value: answerId }) => {
        const checked = value[answerId] === answerId;
        return (
          <label
            key={answerId}
            className={cn(styles.choice, { [styles.checked]: checked })}
          >
            <input
              type="checkbox"
              checked={checked}
              onChange={(e) => onChoiceChange(answerId, e.target.checked)}
              className={styles.input}
            />
            {sortedChoices.length > 1 && <span dangerouslySetInnerHTML={{ __html: label }}></span>}
          </label>
        );
      })}
    </div>
  );
};
