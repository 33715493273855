import React from 'react';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie'; 
import { graphql} from 'gatsby';
import { Document } from '@contentful/rich-text-types';
import {RichText} from "../../utils/rich-text";
import cn from 'classnames';

import styles from './notification-message.module.scss';

export interface NotificationMessageProps  {
  notificationMessage?: {
    notificationMessageText?: {
      json: Document;
    };
    notificationBackgroundColour?: string;
    displayNotification: boolean
  };
};

export const NotificationMessage: React.FC<NotificationMessageProps> = ({notificationMessageText, notificationBackgroundColour, displayNotification}) => {
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const handleCloseClicked = () => {
    setNotificationVisible(false);
    Cookies.set('NotificationDismissed', 'true');
  }
  useEffect(() => {
    if(firstLoad) {
      const cookieNotificationFlag = !!Cookies.get('NotificationDismissed');
      setTimeout(() => {
        setNotificationVisible(displayNotification && !cookieNotificationFlag);
      }, 1000);
      setFirstLoad(false);
    }
  }, [firstLoad, displayNotification])
  return (<div className={cn(styles.notificationswrapper, {[styles.notificationswrappershow]: notificationVisible} )} style={{backgroundColor: notificationBackgroundColour}}>
            <RichText content={notificationMessageText} />
            <button onClick={handleCloseClicked} className={styles.notificationCloseButton}>&#x2715;</button>
          </div>);
}




export default NotificationMessage;

export const query = graphql`
  fragment NotificationFrag on ContentfulNotificationMessage {
    notificationMessageText {
      json
    }
    notificationBackgroundColour
    displayNotification
  }
`;
