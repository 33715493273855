import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useMemo, useState } from 'react';
import { Document } from '@contentful/rich-text-types';
import cn from 'classnames';
import { Question } from './question';
import { sortFn } from './utils';
import { FormValueType, FormQuestion, GDPRValueType } from './types';
import { HubspotGdprBlock } from './HubspotGdprBlock/HubspotGdprBlock';
  

import styles from './form.module.scss';
import { RichText } from '../../../utils/rich-text';

export interface HubspotFormProps {
  name,
  id,
  fields: {
    fieldType: string,
    label: string,
    name: string,
    options: {
      displayOrder: number,
      label: string,
      value: string,      
    }[],
    defaultValue: string,
    displayOrder: number,
    type: string,
    placeholder: string,
    required: boolean
  }[]

}
interface FormProps {
  heading: {
    json: Document;
  };
  formId: string;
  className?: string;
  value: FormValueType;
  gdprValue: GDPRValueType;
  onGDPRChange?: (x: GDPRValueType) => void;
  onChange: (x: FormValueType) => void;
  showValidation: boolean;
  onValid: (x: boolean) => void;
  hideNumbers: boolean
}

export const Form: React.FC<FormProps> = ({
  heading,
  formId,
  className,
  value,
  gdprValue,
  onChange,
  onGDPRChange,
  showValidation,
  onValid,
  hideNumbers
}) => {
  const onValueChange = ({    
    question,
    answer,
  }: {
    question: string;
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    answer: any;
  }) => {
    onChange({...value, [question]: answer})
  };

  const forms = useStaticQuery(graphql`
      query HubspotFormQuery {
        allHubspotForm {
          nodes {
            name,
            id,
            metaData {
              name,
              value
            },
            formFieldGroups{
              fields {
                fieldType,
                label,
                name,
                options {
                  displayOrder,
                  label,
                  value
                },
                defaultValue,
                displayOrder,
                type,
                placeholder,
                required,
                validation {
                  name,
                  message,
                  data
                }
              }
            }
          }
        }        
    }`)?.allHubspotForm?.nodes;


  // only use the form related to this apply module
  const form = forms.find((form) => form.id === formId) || null;

  const flattenedFormFields = form?.formFieldGroups?.reduce((combined, formFieldGroup) => {
    return combined.concat(formFieldGroup.fields);
  }, []);

  const [validationMessages, setValidMessages] = useState(
    flattenedFormFields?.reduce((acc, { name }) => ({ [name]: null }), {})
  );
  const onValidChange = (name, validationMessage) => {
    setValidMessages((current) => ({
      ...current,
      [name]: validationMessage,
    }));
  };


  useEffect(() => {
    validationMessages && onValid(Object.values(validationMessages).every((isValid) => !isValid));
  }, [validationMessages]);

  // do we have gdpr options?
  const legalConsentOptions = form?.metaData?.find((a) => {
    return a.name == "legalConsentOptions";
  });

  if(legalConsentOptions) {

    const legalConsentOption = JSON.parse(legalConsentOptions.value); 

    if(legalConsentOption.processingConsentType == "IMPLICIT") {
  
      // set initial gdpr value to YES for processing
      if(!gdprValue?.consent?.consentToProcess) {        
        onGDPRChange({...gdprValue, consent: {...gdprValue.consent, consentToProcess: true, text: legalConsentOption.processingConsentText || "[No text displayed]"}});
      }
  
    }
  
  }


  if(!form) {
    return null;
  }
  return (
    
    <div className={cn('form', className)}>
      <RichText className={styles.heading} content={heading} />
        <ol className={cn('page', styles.form)}>
          {flattenedFormFields.map((question) => {
            return (
            <Question
              key={question.name}
              {...question}
              value={value?.[question.name]}
              onChange={({ id, value }) => {
                onValueChange({ question: id, answer: value });
              }
                
              }
              onValidationMessage={(isValid) =>
                {
                  onValidChange(question.name, isValid);
                }
              }
              validationMessage={
                showValidation && validationMessages[question.name]
              }
              hideNumbers={hideNumbers}
            />
          )})}
        </ol>
        {legalConsentOptions && <HubspotGdprBlock 
            displayedValidationMessage={
              showValidation && validationMessages['gdpr']
            }        
            onValidationMessage={(isValid) =>
              {
                onValidChange('gdpr', isValid);
              }
            }
            consentOptions={legalConsentOptions.value} 
            gdprValues={gdprValue} 
            onChange={(gdprValue) => { onGDPRChange(gdprValue) } } 
            /> 
        }
    </div>
  );
};