import cn from 'classnames';
import styles from './video-overlay.module.scss';
import { Modal } from '../modal';
import React from 'react';
import { useEffect, useRef, useState } from 'react';

interface VideoOverlayProps {
  url: string;
  onClose: () => void;
}

export const VideoOverlay: React.FC<VideoOverlayProps> = ({ url, onClose }) => {
  const videoDivRef = useRef<HTMLDivElement>();

  const [closeVideo, setCloseVideo] = useState(false);

  useEffect(() => {
    if (!closeVideo || !videoDivRef.current) {
      return;
    }
    const x = setTimeout(() => {
      setCloseVideo(false);
      onClose();
    }, 600);
    videoDivRef.current.addEventListener('animationend', () => {
      clearTimeout(x);
      setCloseVideo(false);
      onClose();
    });
  }, [closeVideo, onClose]);

  return (
    <Modal>
      <div
        className={cn(styles.modal, { [styles.hideModal]: closeVideo })}
        ref={videoDivRef}
        onClick={() => setCloseVideo(true)}
      >
        <iframe
          src={url}
          width="640"
          height="361"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          title="Reset health video"
          className={styles.videoWrapper}
        ></iframe>
      </div>
    </Modal>
  );
};
