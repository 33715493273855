import React from 'react';
import { graphql } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import { Logo } from '../../utils';

import styles from './logo-banner.module.scss';

interface LogoBannerProps {
  logo: {
    title: string;
    fluid: FluidObject | FluidObject[];
    svg: {
      dataURI: string;
    };
  };
}

export const LogoBanner: React.FC<LogoBannerProps> = ({ logo }) => {
  return <Logo className={styles.logo} {...logo} />;
};

export const fragment = graphql`
  fragment LogoBannerFrag on ContentfulLogoBanner {
    logo {
      title
      fluid(quality: 100) {
        ...GatsbyContentfulFluid
      }
      svg {
        dataURI
      }
    }
  }
`;
