import React from 'react';
import { graphql, Link } from 'gatsby';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import { Document } from '@contentful/rich-text-types';
import cn from 'classnames';
import BackgroundImage from 'gatsby-background-image';

import styles from './marketing-module.module.scss';
import { RichText } from '../../utils';

interface MarketingModuleProps {
  body?: {
    json: Document;
  };
  cta?: {
    target: string;
    text: string;
  };
  fullWidth?: boolean;
  heading?: {
    json: Document;
  };
  textColour?: {
    rgb: string;
  };
  shadowBox?: boolean;
  logo?: {
    svg?: {
      dataURI: string;
    };
    fluid?: FluidObject | FluidObject[];
    title: string;
  };
  image: {
    fluid: FluidObject | FluidObject[];
  };
  halfWidthImage: boolean;
}

export const MarketingModule: React.FC<MarketingModuleProps> = ({
  body,
  heading,
  textColour,
  cta,
  fullWidth,
  shadowBox,
  logo,
  image,
  halfWidthImage,
}) => {
  const isFullWidth = fullWidth !== false;
  const textColourStyle = textColour ? { color: textColour.rgb } : {};
  return (
    <BackgroundImage
      className={cn(styles.marketingModule, {
        [styles.fullWidth]: isFullWidth,
        [styles.container]: !isFullWidth,
        [styles.halfWidthImage]: halfWidthImage,
      })}
      fluid={image?.fluid}
    >
      <div
        className={cn(styles.contentContainer, {
          [styles.shadowBox]: shadowBox,
        })}
        style={textColourStyle}
      >
        <div className={styles.paddingBox}>
          <div className={styles.logoWrapper}>
            {logo?.svg && (
              <img
                className={styles.logo}
                src={logo.svg.dataURI}
                alt={logo.title}
              />
            )}
            {logo?.fluid && (
              <GatsbyImage className={styles.logo} fluid={logo.fluid} />
            )}
          </div>
          {heading && <RichText content={heading} component="h2" />}
          {body && <RichText className={styles.textBody} content={body} />}
          {cta && (
            <Link to={cta.target} className={styles.button}>
              {cta.text}
            </Link>
          )}
        </div>
      </div>
    </BackgroundImage>
  );
};

export const fragment = graphql`
  fragment MarketingFrag on ContentfulMarketingModule {
    body {
      json
    }
    cta {
      target
      text
    }
    fullWidth
    heading {
      json
    }
    textColour {
      rgb
    }
    shadowBox
    logo {
      title
      svg {
        dataURI
      }
      fluid(quality: 75, maxWidth: 3840 ) {
        ...GatsbyContentfulFluid
      }
    }
    image {
      fluid(quality: 75, maxWidth: 3840 ) {
        ...GatsbyContentfulFluid
      }
    }
    halfWidthImage
  }
`;
