import { Document } from '@contentful/rich-text-types';
import { FluidObject } from 'gatsby-image/index';
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie'; 
import {
  AppStore,
  AppStoreContentfulProps,
} from '../../utils/app-store';
import {
  graphql,
  Link,
} from 'gatsby';

import styles from './apply.module.scss';
import { RichText } from '../../utils/rich-text';
import cn from 'classnames';
import {
  Form,
  HubspotFormProps,
} from './form/form';
import { FormValueType, GDPRValueType } from './form/types';
import { VideoOverlay } from '../../utils/video-overlay';
import BackgroundImage from "gatsby-background-image";

export interface ApplyProps {
  applyHeading: {
    json: Document;
  };
  subHeading: {
    json: Document;
  };
  successHeading: {
    json: Document;
  };
  failureHeading: {
    json: Document;
  };
  formHeading: {
    json: Document;
  };
  hubspotFormId: string;
  applyButtonText: string;
  failureButtonText: string;
  successCta: {
    target: string;
    text: string;
    openInNewPage: boolean;
  };
  appStore: AppStoreContentfulProps;
  collectorId: string;
  name: string;
  videoHeader: {
    json: Document;
  };
  videoLink: {

    linkText: {
      json: Document;
    };
    thumbnail: {
      fluid: FluidObject
    }
    url: string;
  };
}

enum StateEnum {
  APPLYING = 'applying',
  PROCESSING = 'process',
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export const Apply: React.FC<ApplyProps & { form: HubspotFormProps }> = ({
                                                                       applyHeading,
                                                                       subHeading,
                                                                       successHeading,
                                                                       failureHeading,
                                                                       formHeading,
                                                                       applyButtonText,
                                                                       failureButtonText,
                                                                       hubspotFormId,
                                                                       successCta,
                                                                       appStore,
                                                                       form,
                                                                       collectorId,
                                                                       name,
                                                                       videoHeader,
                                                                       videoLink,
                                                                     }) => {
  const [state, setState] = useState(StateEnum.APPLYING);
  const [formValue, setFormValue] = useState<FormValueType>({});
  const [gdprOptions, setGDPROptions] = useState<GDPRValueType>({});
  const [isValid, setIsValid] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isValid) {
      setShowValidation(true);
      return;
    }
    setState(StateEnum.PROCESSING);
    try {

      const result = await fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.GATSBY_HUBSPOT_PORTAL_ID}/${hubspotFormId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': "application/json"
          },
          body: JSON.stringify({
              fields: Object.entries(formValue).map(
                ([questionId, answer]) => ({
                    name: questionId,
                    value: typeof(answer) === "object" ? Object.entries(answer).map((x) => { return x[1]; }).join(";") : answer,
                }),
              ),
              context: {
                hutk: Cookies.get('hubspotutk'),
                pageUri: window.location ? window.location.hostname + window.location.pathname : '',
                pageName: document ? document.title : ''
              },
              legalConsentOptions: gdprOptions.consent ? gdprOptions : null
          }),
        },
      );
      if (result.ok) {
        setState(StateEnum.SUCCESS);
        return;
      }
    }
    catch (e) {
      // do nothing
    }
    finally {
      window.scroll({ top: 0, behavior: 'smooth' });
    }
    setState(StateEnum.FAILURE);
  };

  return (
    <section className={styles.container}>
      <div className={styles.column}>
        <div className={cn(styles.heading, styles[state])}>
          <RichText
            className={styles.applyHeader}
            content={applyHeading}
            component="h2"
          />
          <RichText
            className={styles.successHeader}
            content={successHeading}
            component="h2"
          />
          <RichText
            className={styles.failureHeader}
            content={failureHeading}
            component="h2"
          />
          <RichText className={styles.subHeading} content={subHeading}/>
        </div>
        <hr className={styles.divider}/>
        {state === StateEnum.SUCCESS && (
          <Link
            className={cn(styles.button, styles.cta)}
            to={successCta.target}
            {...(successCta.openInNewPage
              ? { target: '_blank', rel: 'noreferrer' }
              : {})}
          >
            {successCta.text}
          </Link>
        )}
        {state === StateEnum.FAILURE && (
          <button
            className={cn(styles.button, styles.cta)}
            onClick={() => setState(StateEnum.APPLYING)}
          >
            {failureButtonText}
          </button>
        )}
      </div>
      {[StateEnum.APPLYING, StateEnum.PROCESSING].includes(state) && (
        <Form
          className={cn(styles.form, {
            [styles.formHidden]: ![
              StateEnum.APPLYING,
              StateEnum.PROCESSING,
            ].includes(state),
          })}
          heading={formHeading}
          gdprValue={gdprOptions}
          formId={hubspotFormId}
          value={formValue}
          onGDPRChange={(x) => { setGDPROptions(x);}}
          onChange={(x) => {setFormValue(x);}}
          onValid={(valid) => setIsValid(valid)}
          showValidation={showValidation}
        />
      )}
      {state === StateEnum.APPLYING && (
        <button
          className={cn(styles.button, styles.cta, styles.apply)}
          onClick={submitForm}
        >
          {applyButtonText}
        </button>
      )}
      {state === StateEnum.PROCESSING && <div className={styles.loader}/>}
      {state === StateEnum.SUCCESS && (
        <>
          <AppStore className={styles.appStore} {...appStore} />
          <div className={styles.thumbnail}>
            <RichText className={styles.videoHeader} content={videoHeader}/>
            <BackgroundImage
              fluid={videoLink.thumbnail.fluid}
              className={styles.thumbnail__image}
            >
              <div className={styles.watchVideo}>
                <div
                  className={styles.videoLink}
                  onClick={() => setShowVideo(true)}
                  role="button"
                  tabIndex={0}
                >
                  <div className={styles.videoButton}></div>
                  <RichText content={videoLink.linkText}/>
                </div>
              </div>
            </BackgroundImage>
          </div>
        </>
      )}
      {showVideo && (
        <VideoOverlay url={videoLink.url} onClose={() => setShowVideo(false)}/>
      )}
    </section>
  );
};

export const fragment = graphql`
  fragment ApplyFrag on ContentfulApply {
    applyHeading {
      json
    }
    subHeading {
      json
    }
    successHeading {
      json
    }
    failureHeading {
      json
    }
    formHeading {
      json
    }
    applyButtonText
    failureButtonText
    successCta {
      target
      text
      openInNewPage
    }
    hubspotFormId
    appStore {
      heading {
        json
      }
      appleUrl
      googleUrl
    }
    collectorId
    name
    videoHeader {
      json
    }
    videoLink {
      linkText {
        json
      }
      thumbnail {
        fluid(quality: 75){
          ...GatsbyContentfulFluid
        }
      }
      url
    }
  }
  
`;
