import React from 'react';
import { graphql } from 'gatsby';

interface AnchorProps {
  target: string;
}

export const Anchor: React.FC<AnchorProps> = ({ target }) => (
  <div id={target} />
);

export const fragment = graphql`
  fragment AnchorFrag on ContentfulAnchor {
    target
  }
`;
