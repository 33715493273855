import {
  useStaticQuery,
  graphql,
} from 'gatsby';

export interface SiteMetadataProps {
  pageConfig: {
    [key: string]: unknown;
  };
  siteUrl: string;
  title: string;
}

const UseSiteMetadata = (): SiteMetadataProps => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            pageConfig{
              home {
                testimonialsCount
              }
            }
            siteUrl
            title
          }
        }
      }
    `,
  );
  return site.siteMetadata;
};

export { UseSiteMetadata };
