import { graphql } from 'gatsby';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import { Document } from '@contentful/rich-text-types';
import slugify from 'react-slugify';
import { RichText } from '../../utils/rich-text';
import React from 'react';
import styles from './rich-text-image.module.scss';
import cn from 'classnames';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';


interface RichTextImageProps {
  title: {
    json: Document;
  };
  subTitle: {
    json: Document;
  };
  block1: {
    json: Document;
  };
  block2: {
    json: Document;
  };
  imageAlign: boolean;
  image: {
    fluid: FluidObject | FluidObject[];
  };
}

export const RichTextImage: React.FC<RichTextImageProps> = ({
                                                              title,
                                                              subTitle,
                                                              block1,
                                                              block2,
                                                              image,
                                                              imageAlign,
                                                            }) => {
  const id = subTitle && slugify(documentToPlainTextString(subTitle?.json) || '');
  return (
    <section className={styles.container}>
      {title && <RichText content={title} component="h2"/>}
      <div className={styles.imageWrapper}>
        {image && (
          <GatsbyImage
            fluid={image.fluid}
            className={cn(styles.image, {
              [styles.alignLeft]: imageAlign,
              [styles.alignRight]: !imageAlign,
            })}
          />
        )}
        {block1 && <RichText className={styles.textBlocks} content={block1}/>}
      </div>
      {subTitle && <RichText content={subTitle} component="h3" id={id}/>}
      {block2 && <RichText className={styles.textBlocks} content={block2}/>}
    </section>
  );
};

export const fragment = graphql`
  fragment RichTextImageFrag on ContentfulRichTextWithImage {
    title {
      json
    }
    subTitle {
      json
    }
    block1 {
      json
    }
    block2 {
      json
    }
    imageAlign
    image {
      fluid(quality: 100) {
        ...GatsbyContentfulFluid
      }
    }
  }
`;
