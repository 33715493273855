import {
  graphql,
} from 'gatsby';
import React from 'react';
import styles from './footer-block.module.scss';

export interface FooterBlockContentfulProps {
  companyAddress: string;
  companyNumber: number;
  id: string;
  name: string;
  registeredCompanyName: string;
}

export const FooterBlock: React.FC<FooterBlockContentfulProps> = ({
                                                                    companyAddress,
                                                                    companyNumber,
                                                                    registeredCompanyName,
                                                                  }) => {

  return (
    <section className={styles.module}>
      <div className={styles.container}>
        <p className={styles.text}>
          <span>&copy; {new Date().getFullYear()} {registeredCompanyName}</span>
          <span>Company number {companyNumber}</span>
          Registered at {companyAddress.split(',').map((c, i) => <span key={`company-${i}`} className={styles.company}>{c}</span>)}
        </p>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment FooterBlockFrag on ContentfulFooterBlock {
    companyAddress
    companyNumber
    id
    name
    registeredCompanyName
  }
`;

