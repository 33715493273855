import React from 'react';

import styles from './summary-module.module.scss';
import cn from 'classnames';
import {Link} from "gatsby";

export interface DocumentOrLinkProps {
  showAll?: boolean;
  document?: {
    file: {
      url: string;
    };
  };
  link?: {
    id: string;
    target: string;
    text: string;
    openInNewPage: boolean;
  }
}

export const DocumentOrLink: React.FC<DocumentOrLinkProps> = ({children, showAll, ...props}) => {
  if(props.document) {
    return (
      <a
        className={cn(styles.summary, { [styles.showAll]: showAll })}
        target="_blank"
        rel="noreferrer"
        href={props.document.file.url}
      >
        {children}
      </a>
    );
  }

  const { target, openInNewPage } = props.link;
  
  const targetProps = openInNewPage
  ? { target: '_blank', rel: 'noreferrer' }
  : {};

  return (
    <Link
      className={cn(styles.summary, { [styles.showAll]: showAll })}
      to={target}
      {...targetProps}
    >
      {children}
    </Link>
  )
}