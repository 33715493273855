import { graphql, Link } from 'gatsby';
import { Document } from '@contentful/rich-text-types';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import Carousel from '@brainhubeu/react-carousel';
import { mobileMaxWidth, RichText } from '../../utils';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { DocumentOrLink } from './DocumentOrLink';

import styles from './summary-module.module.scss';
import { useMediaQuery } from '@react-hook/media-query';

interface SummaryModuleProps {
  title?: {
    json: Document;
  };
  cta?: {
    target: string;
    text: string;
  };
  showAll?: boolean;
  summaries?: {
    summaries?: {
      id?: string;
      title?: {
        json: Document;
      };
      summary?: {
        json: Document;
      };
      image?: {
        fluid: FluidObject | FluidObject[];
      };
      avatar?: {
        fluid: FluidObject | FluidObject[];
      };
      document?: {
        file: {
          url: string;
        };
      };
      link?: {
        id: string;
        target: string;
        text: string;
        openInNewPage: boolean;
      }
    }[];
  };
}

export const SummaryModule: React.FC<SummaryModuleProps> = ({
  title,
  cta,
  showAll,
  summaries,
}) => {
  const isMobile = useMediaQuery(`(max-width: ${mobileMaxWidth}px)`);
  const [isClientSide, setIsClientSide] = useState(Boolean(showAll));
  const sectionRef = useRef<HTMLDivElement>();
  const [enableAutoplay, setEnableAutoplay] = useState(true);

  useEffect(() => {
    setIsClientSide(true); //force render or isMobile can have incorrect value;
  }, []);

  const Wrapper =
    showAll || !isMobile
      ? ({ children }) => (
          <div className={styles.summariesWrapper}>{children}</div>
        )
      : ({ children }) => (
          <div className={styles.summariesWrapper} onTouchStart={() => setEnableAutoplay(false) }>
            <Carousel
              infinite
              autoPlay={enableAutoplay ? 4000 : null}
              draggable
              slidesPerPage={1.05}
              stopAutoPlayOnHover
            >
              {children}
            </Carousel>
          </div>
        );

  const displaySummary = useMemo(() => {
    if (!summaries?.summaries?.length) {
      return [];
    }
    let n = 3,
      len = summaries.summaries.length;
    if (showAll || summaries.summaries.length <= n) {
      return summaries.summaries;
    }
    const result = new Array(n),
      taken = new Array(len);
    while (n--) {
      const x = Math.floor(Math.random() * len);
      result[n] = summaries.summaries[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }, [summaries, showAll]);

  return (
    <section
      key={isClientSide.toString()}
      className={cn(styles.container, styles.summaryModule, {
        [styles.showAll]: showAll,
      })}
      ref={sectionRef}
    >
      {title && (
        <RichText
          className={styles.moduleTitle}
          content={title}
          component="h2"
        />
      )}
      {cta && (
        <Link
          to={cta.target}
          className={cn(styles.cta, styles.button, {
            [styles.showAll]: showAll,
          })}
        >
          {cta.text}
        </Link>
      )}
      {isClientSide && (
        <Wrapper>
          {displaySummary.map(
            ({ id, title: summaryTitle, summary, image, avatar, document, link }) => (
              <DocumentOrLink
                key={id}
                document={document}
                link={link}
                showAll
              >
                {image && (
                  <GatsbyImage className={styles.image} fluid={image.fluid} />
                )}
                {summaryTitle && (
                  <RichText
                    className={styles.summaryTitle}
                    content={summaryTitle}
                    component="h3"
                  />
                )}
                <div className={styles.summaryWrapper}>
                  {avatar && (
                    <GatsbyImage
                      className={styles.avatar}
                      fluid={avatar.fluid}
                    />
                  )}
                  {summary && (
                    <RichText
                      className={styles.summaryText}
                      content={summary}
                    />
                  )}
                </div>
              </DocumentOrLink>
            )
          )}
        </Wrapper>
      )}
    </section>
  );
};
export const fragment = graphql`
  fragment SummariesFragment on ContentfulSummariesModule {
    title {
      json
    }
    cta {
      target
      text
    }
    showAll
    summaries {
      summaries {
        id
        title {
          json
        }
        summary {
          json
        }
        image {
          fluid(quality: 75) {
            ...GatsbyContentfulFluid
          }
        }
        avatar {
          fluid(quality: 75) {
            ...GatsbyContentfulFluid
          }
        }
        document {
          file {
            url
          }
        }
        link {
          text
          target
          openInNewPage
        }
      }
    }
  }
`;
