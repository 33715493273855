import React from 'react';
import { useState } from 'react';
import {
  graphql,
  Link,
} from 'gatsby';
import { Document } from '@contentful/rich-text-types';
import cn from 'classnames';
import {
  FooterBlock,
  FooterBlockContentfulProps,
} from '../footer-block';
import Cookies from 'js-cookie';
import styles from './footer.module.scss';
import { Logo, LogoContentfulProps, RichText } from '../../utils';

const EMAIL_REGEX = /.+@.+\...+/; //very rudimentary email check;
export interface FooterContentfulProps {
  logo: LogoContentfulProps;
  details: {
    json: Document;
  };
  linkColumns: {
    id: string;
    links: {
      id: string;
      target: string;
      text: string;
      openInNewPage: boolean;
    }[];
    title: {
      json: Document;
    };
  }[];
  mailingListColumnTitle: {
    json: Document;
  };
  emailButtonText: string;
  surveyCollectorId: string;
  footerBlock: FooterBlockContentfulProps
}

export const Footer: React.FC<FooterContentfulProps> = ({
                                                                                                             logo,
                                                                                                             linkColumns,
                                                                                                             mailingListColumnTitle,
                                                                                                             details,
                                                                                                             surveyCollectorId,
                                                                                                             emailButtonText,
                                                                                                             footerBlock,
                                                                                                           }) => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setError(null);
    setSuccess(null);
  };
  const submitEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    if (!EMAIL_REGEX.test(email)) {
      setError('invalid email');
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.GATSBY_HUBSPOT_PORTAL_ID}/${process.env.GATSBY_HUBSPOT_EMAIL_FORM_ID}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': "application/json"
          },
          body: JSON.stringify({
              fields: [{
                name: 'email',
                value: email
              }],
              context: {
                hutk: Cookies.get('hubspotutk'),
                pageUri: window.location ? window.location.hostname + window.location.pathname : '',
                pageName: document ? document.title : ''
              }
          }),
        },
      );
      if (response.ok) {
        setSuccess('email address registered');
        return;
      }
      if (response.status === 409) {
        setError('invalid email address');
        return;
      }        
    }
    catch (e) {
      //do nothing;
    }
    finally {
      setIsSubmitting(false);
    }
    setError('unknown error registering email address');
  };
  const linkItems = [];
  return (
    <div className={styles.page}>
      <section className={styles.module}>
        <div className={styles.container}>
          {logo && <Logo {...logo} className={styles.logo}/>}        
          <div className={styles.columns}>
            {linkColumns.map(({ title, links, id }) => {
              linkItems.push(id); // some id's are repeated, so we're just making unique ids
              return (
                <div
                  key={id + linkItems.filter((li) => li === id).length}
                  className={styles.column}
                >
                  <RichText
                    className={styles.columnTitle}
                    content={title}
                    component="h3"
                  />
                  <div className={styles.links}>
                    {links.map(({ id, target, text, openInNewPage }) => {
                      linkItems.push(id); // some id's are repeated, so we're just making unique ids
                      return openInNewPage ? (
                        <a
                          key={id + linkItems.filter((li) => li === id).length}
                          href={target}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {text}
                        </a>
                      ) : (
                        <Link key={id} to={target}>
                          {text}
                        </Link>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className={cn(styles.column, styles.detailsColumn)}>
            {mailingListColumnTitle && (
              <RichText
                className={styles.columnTitle}
                content={mailingListColumnTitle}
                component="h3"
              />              
            )}
            {details && <RichText className={styles.details} content={details}/>}
            {surveyCollectorId && (
              <form className={styles.emailForm} onSubmit={submitEmail}>
                <div className={styles.inputWrapper}>
                  <input
                    className={cn(styles.email, {
                      [styles.processing]: isSubmitting,
                    })}
                    type="email"
                    value={email}
                    onChange={onEmailChange}
                    placeholder="Email address"
                    aria-label="email address"
                  />
                  <button
                    className={cn(styles.button, { [styles.hide]: isSubmitting })}
                    type="submit"
                  >
                    {emailButtonText || 'Join!'}
                  </button>

                  {isSubmitting && <div className={styles.loader}/>}
                </div>
                {error && <span className={styles.error}>{error}</span>}
                {success && <span className={styles.success}>{success}</span>}
              </form>
            )}
          </div>
        </div>

      </section>
    <FooterBlock {...footerBlock}/>
</div>
  );
};

export const query = graphql`
  fragment FooterFrag on ContentfulFooter {
    logo {
      title
      fluid(quality: 75, maxWidth: 3840 ) {
        ...GatsbyContentfulFluid
      }
      svg {
        dataURI
      }
    }
    details {
      json
    }
    linkColumns {
      id
      links {
        id
        target
        text
        openInNewPage
      }
      title {
        json
      }
    }
    mailingListColumnTitle {
      json
    }
    emailButtonText
    surveyCollectorId
    footerBlock{
      ...FooterBlockFrag
    }
  }

`;
