import { graphql, Link } from 'gatsby';
import React from 'react';
import styles from './cta.module.scss';

interface CtaProps {
  text: string;
  target: string;
  openInNewPage: boolean;
}

export const Cta: React.FC<CtaProps> = ({ text, target, openInNewPage }) => {
  const targetProps = openInNewPage
    ? { target: '_blank', rel: 'noreferrer' }
    : {};
  return (
    <section className={styles.container}>
      {target.toLowerCase().startsWith('mailto') && (<a className={styles.button} href={target}>{text}</a>)}
      {!target.toLowerCase().startsWith('mailto') && (
        <Link className={styles.button} to={target} {...targetProps}>
          {text}
        </Link>
      )}
    </section>
  );
};

export const fragment = graphql`
  fragment CtaFrag on ContentfulLink {
    text
    target
    openInNewPage
  }
`;
